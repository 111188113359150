import { applyMiddleware, createStore } from 'redux';
import promiseActions from '@synergycms_core/middlewares/promiseActions';

import { Map } from 'immutable';
import { contains } from 'ramda';

import rootReducer from './store/reducers';

import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

let middlewares = [promiseActions];

//xapusilla per habilitar middlewares de testing, aqui encara no s'ha carregat l'accio de parametres, no hi ha gaire més remei que fer això...
const testingMode = contains('testing_mode=true', window.location.search);
const extendedTestingMode = testingMode && contains('extended_testing_mode=true', window.location.search);
if (process.env.NODE_ENV !== 'production' && testingMode) {
  const logger = createLogger({
    collapsed: true,
    diff: extendedTestingMode,
    stateTransformer: state => {
      // Not all the reducers are Immutable structures so have
      // to check if they are for each one.
      // This function could get very costly over time, but it's
      // super useful for debugging.
      if (!extendedTestingMode) {
        return null;
      }
      if (state.toJS) {
        return state.toJS();
      }
      return state;
    },
    actionTransformer: action => {
      const newAction = { ...action };
      if (!extendedTestingMode) {
        newAction.payload = null;
        return newAction;
      }
      if (newAction.payload && newAction.payload.toJS) {
        newAction.payload = newAction.payload.toJS();
      }
      return newAction;
    }
  });

  middlewares = [...middlewares, logger];
}

middlewares = applyMiddleware(...middlewares);

const store =
  process.env.NODE_ENV !== 'production' && extendedTestingMode
    ? createStore(rootReducer, Map(), composeWithDevTools(middlewares))
    : createStore(rootReducer, Map(), middlewares);

export default store;
