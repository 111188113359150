import React from 'react';
import Cookies from '@synergycms_core/utils/cookies';
import Engine from '@synergycms_core/components/functional/Engine';
import ParamsParser from '@synergycms_core/utils/paramsParser';
import { compose, pipe, filter, pluck, is, length, merge, has } from "ramda";
import { get } from '@synergycms_core/utils'
import { toIsoLangCode } from '@synergycms_core/utils/api';
import { connect } from 'react-redux';
import actions from './store/actions';
import { fromJS } from 'immutable';
import moment from 'moment';
import PropertyManager from '@synergycms_core/components/HoC/propertyManager';
import { enableCachePrices, contextualizeAppConfig } from "@synergycms_core/store/app/utils";


const app = class App extends React.Component {
  static displayName = 'App';

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  dispatchEvent = queryParams => {
    if (typeof window.CustomEvent === 'function') {

      const additionalQueryParams = {
        href: window.location.href
      };

      if (has('hotelCodes', queryParams)) {
        additionalQueryParams.hotels = queryParams.hotelCodes;
      } else if (has('hotelCode', queryParams)) {
        additionalQueryParams.id_hotel = queryParams.hotelCode;
      }

      const event = new CustomEvent('engine_search', {
        detail: {
          engine: merge(additionalQueryParams, queryParams)
        }
      });
      document.dispatchEvent(event);
    }
  };

  getOtherParams = () => {
    const queryParams = {};

    // JSON SID
    if (window.json_sid) {
      queryParams.sid = window.json_sid;
    }

    // Get URL Params
    const urlParams = [
      'avail_code',
      'company_id',
      'referer_code',
      'gift_voucher',
      'id_referer',
      'promotion_code',
      'td',
      'msclkid',
      'trv_reference',
      'clickID',
      'refid',
      'gclid',
      'yclid',
      'start',
      'end',
      '_askSI'
    ];


    urlParams.forEach(urlParam => {

      // consult urlParam in cookies and get this value
      const cookieValue = Cookies.get(urlParam);
      if (cookieValue) {
        queryParams[urlParam] = cookieValue;
      }
      const value = new RegExp('[?&]' + urlParam + '=([^&#]*)').exec(window.location.href);
      if (length(value)) {
        queryParams[urlParam] = get(1, value);
      }

    });

    return queryParams;
  };

  executeSearch = (objectParams) => {
    const { data } = this.props.config;
    let hotels = null;
    let hotelCode = null;
    const selected = objectParams.selectedSearch;
    if (data.web_type === "PORTAL" || (data.web_type === "WI" && data.group === "eurostarshotels")) {
      const country_id = !selected.country_id && !selected.code ? selected.id : null;
      const city_id = selected.country_id ? selected.id : null;

      hotelCode = selected.id && selected.code ? selected.code : null;
      if (city_id) {
        hotels = pipe(
            filter(hotel => hotel.city_id === city_id),
            pluck('code')
        )(data.hotels_info.hotels);
      } else if (country_id) {
        hotels = pipe(
            filter(hotel => hotel.country_id === country_id),
            pluck('code')
        )(data.hotels_info.hotels);
      }
    } else {
      hotelCode = data.hotel_code;
    }

    if (hotels && hotels.length === 1) {
      hotelCode = hotels[0];
      hotels = null;
    }

    const queryParams = merge({
      hotelCodes: hotels,
      hotelCode: hotelCode,
      city_id: (selected.country_id && !selected.code ) ? selected.id : (selected.city_id) ? selected.city_id : null,
      country_id: !selected.country_id && !selected.code ? selected.id : null,
      start: objectParams.start,
      end: objectParams.end,
      occups: objectParams.occups,
      promotion_code: objectParams.promoCode,
    }, this.getOtherParams());

    this.dispatchEvent(queryParams);

    const urlParms = ParamsParser.convertParamsToStringQuery(queryParams);

    let uri = "";
    if (is(String, data.url)) {
      uri = data.url;
    } else {
      uri = data.url[toIsoLangCode(data.lang)];
      uri = uri ? uri : uri["en"];
    }

    //cuando hay solo un hotel, ir a su mono si enable_wi_url existe
    if(hotelCode && !!window.engine_sygy_data.enable_wi_url){
      const hotelInfo = window.engine_sygy_data.hotels_info?.hotels?.find(function (hotel) {
        return hotelCode === hotel.code;
      });
      if(hotelInfo){
        uri = hotelInfo.avail;
      }
    }

    window.location.href = uri + '?' + urlParms;
  };

  render() {
    const urlParams = this.getOtherParams();
    const { config } = this.props;
    const { data, engineActive,pricesActive } = config;

    // Si engineActive es false, no renderiza el motor.
    if (!engineActive) {
      return null;
    }

    return (
        <div >
          <Engine
              executeSearch={this.executeSearch}
              selectSearch={config.selectSearch}
              onChangeCalendar={pricesActive && config.onChangeCalendar}
              params={{ ...data, hotelCode: data.hotel_code, promotion_code: urlParams["promotion_code"],start: urlParams["start"], end: urlParams["end"] }}
              group={data.group}
              corporateLogin={data.corporate_login}
              inAvail={false}
              hotelsInfo={data.hotels_info}
              match={data.match}
              outerWeb={data.outer_web}
              limits={data.limits}
              engineVisible={data.engine_visible}
              dropDownListType={data.drop_down_list_type}
              showModalView={data.show_modal_view}
              focusOnModalView={data.focus_on_modal_view}
              webType={data.web_type}
              lang={data.lang}
              engineSygyData={data.engine_sygy_data}
              links={data.links} />
        </div >
    );
  };

  componentDidMount = () => {
    const { config } = this.props;

    if (config.outerWeb) {
      this.paramsToCookies();
    } else {
      this.props.config.selectSearch();
    }
  }

  //Guardar en cookies
  paramsToCookies() {
    const queryParams = this.getOtherParams();
    var cookieParams = ["referer_code"];

    cookieParams.forEach(function (code) {
      var value = queryParams[code];
      if(value){
        Cookies.set(code, value, 30);
      }
    })

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeCalendar: ({ currentFirstMonth, months }) => {
      if (currentFirstMonth >= moment().startOf("day")) {
        const current = moment();
        const start = current > currentFirstMonth ? current : moment(currentFirstMonth).startOf("month");
        const end = moment(start).add(months - 1, "month").endOf("month");
        dispatch(actions.availCache.getAsync(start, end));
      }
    },
    selectSearch: (selected, range = 3) => {
      if (selected) {
        const hotelId = selected.id && selected.code ? selected.id : null;
        const selection = fromJS({
          date: selected.start,
          city_id: !hotelId && selected.country_id ? selected.id : null,
          country_id: !selected.country_id && !selected.code ? selected.id : null,
          hotelId: hotelId
        });
        dispatch(actions.customer.params.availCacheParams(selection));
        dispatch(actions.customer.currentCurrency.set());
      }
      
      dispatch(actions.availCache.getAsync(moment(), moment().add(range - 1, "month").endOf("month")));
    }
  };
};

const mapStateToProps = store => {
  const hotelId = store.getIn(['customer', 'current_hotel']);
  const engineConfig = contextualizeAppConfig(store, hotelId, "engine_config");
  const pricesActive = enableCachePrices(store);
  const engineActive = engineConfig.getIn(['engine_active'], true);
  return {
    engineActive,
    pricesActive
  };
};


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    PropertyManager([
      'pricesActive',
      'data',
      'engineActive',
      'selectSearch',
      'onChangeCalendar',
    ]),
)(app);
