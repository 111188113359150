require('react-app-polyfill/ie9');

require('react-app-polyfill/stable'); // Custom Event


(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: null
    };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})(); //immutable


var immutable = require('immutable');

var R = require('ramda'); //Immutable
//modificar metodes get i set perquè sempre estebleixin la clau com string
//JS sempre fa conversió de les claus dun punter a string quan realitza les assignacions pero immutable es type sensitive,
//degut a aixo hi han casos en q el type de les claus no coincideix
//la solució es sobrescriure els metodes de get i set perquè estableixin sempre les claus com string


[immutable.Map, immutable.List, immutable.OrderedMap, immutable.OrderedSet, immutable.Set].forEach(function (collectionType) {
  ['get', 'getIn', 'set', 'setIn', 'update', 'updateIn', 'has', 'hasIn', 'includes', 'remove'].forEach(function (method) {
    if (collectionType.prototype[method] && !collectionType.prototype['_' + method]) {
      collectionType.prototype['_' + method] = collectionType.prototype[method];

      collectionType.prototype[method] = function () {
        var newKey = R.head(arguments);
        var key = R.head(arguments);
        var args = R.drop(1, arguments);

        if (R.is(Array, key)) {
          newKey = R.map(function (value) {
            return R.is(Number, value) ? value.toString() : value;
          }, key);
        } else if (R.is(Number, key)) {
          newKey = key.toString ? key.toString() : key;
        }

        return this['_' + method].apply(this, R.prepend(newKey, args));
      };
    }
  });
});